import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"pro-nas"} />
		<Helmet>
			<title>
				SKILLENHANCE
			</title>
			<meta name={"description"} content={"Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань"} />
			<meta property={"og:title"} content={"SKILLENHANCE"} />
			<meta property={"og:description"} content={"Школа, яка допоможе вам оволодіти англійською мовою незалежно від вашого рівня знань"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header2 />
		<Section padding="80px 0 80px 0" background="#ffffff" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Наші курси
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="left"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					width="100%"
				>
					<Strong>
						Гнучкий графік
						<br />
					</Strong>
					Ви самі обираєте час навчання, а наша платформа працює 24/7.{"\n\n "}
					<br />
					<br />
					<Strong>
						Інтерактивний підхід
						<br />
					</Strong>
					Наші курси включають відео, тести та завдання, що роблять процес навчання цікавим та ефективним.
					<br />
					<br />
					<Strong>
						Постійна підтримка
						<br />
					</Strong>
					Ваш особистий тренер завжди готовий відповісти на питання, надати фідбек та допомогти вивчити складні теми.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="--color-lightD1"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--base" lg-text-align="left">
					Цей курс допоможе вам зробити перші кроки у вивченні англійської. Ви навчитеся базовим словам, фразам та граматичним конструкціям.
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Курси для початківців
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Що включає курс?
					</Strong>
					<br />
					Основна граматика: Present Simple, слова-паразити та поширені помилки.
					<br />
					Побутові теми: привітання, покупки, знайомства.
					<br />
					<br />
					Ви зможете зрозуміти прості тексти, вести базову розмову та почуватися впевнено у повсякденних ситуаціях.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="--color-lightD1"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--base" lg-text-align="left">
					Розвивайте свої професійні навички та здобувайте впевненість у спілкуванні з іноземними колегами.
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Англійська для роботи
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					<Strong>
						Програма курсу включає:
					</Strong>
					<br />
					Ділова переписка.
					<br />
					Проведення презентацій та переговорів.
					<br />
					Корисна лексика для бізнесу, IT, маркетингу та інших сфер.
					<br />
					<br />
					<Strong>
						Переваги:
					</Strong>
					{" "}Практичні вправи, кейси та рольові ігри.
					<br />
					<br />
					<Strong>
						Результат:
					</Strong>
					{" "}Ви зможете ефективно працювати в міжнародній команді.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="--color-lightD1"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--base" lg-text-align="left">
					Готуєтеся до IELTS, TOEFL або Cambridge Exams? Наші спеціальні програми забезпечують все, що потрібно для успіху:
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Підготовка до міжнародних іспитів
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Розбір завдань кожної секції.
					<br />
					Розвиток навичок читання, письма, аудіювання та говоріння.
					<br />
					Поради від досвідчених викладачів.
					<br />
					Мок-тести, щоб перевірити готовність.
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Результат:
					</Strong>
					{" "}Ви зможете досягти високого балу та вступити до обраного університету або знайти роботу вашої мрії.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="--color-lightD1"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--base" lg-text-align="left">
					Подорожі світом будуть комфортними, якщо ви володієте мовою.
					<br />
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Англійська для подорожей
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					<Strong>
						Програма:
					</Strong>
					<br />
					Лексика аеропорту, готелів, ресторанів, екскурсій.
					<br />
					Практичні діалоги.x
					<br />
					Інтерактивні завдання для закріплення матеріалу.
					<br />
					<br />
					<Strong>
						Результат:
					</Strong>
					{" "}Ви будете впевнено почуватися у будь-якій країні.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="--color-lightD1"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--base" lg-text-align="left">
					Ми пропонуємо курси для дітей, які допоможуть їм вивчати англійську мову весело, легко та з максимальною користю. Наша програма адаптована під вікові особливості та включає інтерактивні методи навчання.
					<br />
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					<Strong>
						Англійська для дітей
					</Strong>
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					<Strong>
						Програма:
					</Strong>
					<br />
					Ігрові заняття: вивчення мови через пісні, мультфільми та інтерактивні ігри.
					<br />
					Основи граматики та лексики: базовий словниковий запас та прості граматичні конструкції.
					<br />
					Розвиток навичок спілкування: діалоги, рольові ігри та тренування правильної вимови.
					<br />
					<br />
					<Strong>
						Результат:
					</Strong>
					<br />
					Дитина зможе зрозуміти базові фрази, вести просту розмову та проявляти інтерес до подальшого вивчення англійської мови.
					<br />
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="--color-lightD1"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--base" lg-text-align="left">
					Цей курс створений для тих, хто хоче швидко подолати мовний бар'єр та впевнено спілкуватися англійською у різних життєвих ситуаціях.
					<br />
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Розмовна англійська для дорослих
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Програма:
					</Strong>
					<br />
					Навчання у форматі діалогів: повсякденні ситуації, подорожі, робота, дозвілля.
					<br />
					<br />
					Розширення словникового запасу: теми подорожей, культури, спілкування з друзями.
					<br />
					<br />
					Корекція вимови: індивідуальна робота над акцентом та інтонацією.
					<br />
					<br />
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Результат:
					</Strong>
					<br />
					Ви зможете впевнено спілкуватися англійською на побутовому та соціальному рівні, легко підтримувати розмову на різні теми.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" font="--headline2" lg-text-align="center">
					Не знаєте, який курс обрати?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Зателефонуйте нам і ми вам допоможемо.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Навчайтеся, розвивайтеся та досягайте цілей разом із{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						SkillEnhance!
					</Strong>
				</Text>
				<Link
					href="/kontakti"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15:22:07.500Z"
					object-fit="contain"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6744384ce778710018d530d5/images/importance-of-english.webp?v=2024-11-25T15%3A22%3A07.500Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});